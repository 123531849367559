
import { defineComponent } from "vue";

export default defineComponent({
  name: "Navbar",
  data() {
    return {
      opened: false
    };
  }
});
