import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mx-auto text-white" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Navbar = _resolveComponent("Navbar")
  const _component_router_view = _resolveComponent("router-view")
  const _component_Footer = _resolveComponent("Footer")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_Navbar),
    _createVNode(_component_router_view, { class: "pt-16 sm:pt-0" }),
    _createVNode(_component_Footer)
  ]))
}